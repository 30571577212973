/* src/pages/FAQPage.css */
.faq-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    width: 90%;
    margin-bottom: 130px;
  }
  
  .faq-title {
    font-family: var(--font-primary);
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 40px;
  }
  
  .faq-list {
    width: 100%;
    max-width: 1000px;
  }
  
  .faq-item {
    border-top: 1px solid #ddd;
    position: relative;
  }
  
  .faq-item:last-child {
    border-bottom: 1px solid #ddd;
  }


  
  .faq-question-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    cursor: pointer;
  }
  
  .faq-question {
    font-family: var(--font-secondary);
    font-size: 18px;
    text-align: left;
  }
  
  .faq-icon {
    font-size: 24px;
    color: gray;
  }
  
  .faq-answer {
    max-height: 0;
    width: 100%;    
    text-align: left;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
  }
  
  .faq-answer p {
    margin: 0;
    padding: 0px 0 20px 0;
  }
  
  .faq-answer.active {
    max-height: 200px; /* Adjust based on the content height */
    padding: 0px 10px;
  }
  
  .faq-item:first-child::before,
  .faq-item:last-child::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: #ddd;
  }
  
  .faq-item:first-child::before {
    top: 0;
  }
  
  .faq-item:last-child::after {
    bottom: 0;
  }
  


  @media (max-width: 800px) {
    .faq-page-container {
      padding: 40px 0;
      width: 90%;
    }
  }