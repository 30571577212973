/* src/components/TopHeader1.css */
.top-header-container {
    text-align: center;
    position: relative;
    padding: 50px 0;
    height: 700px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .top-header-title {
    font-family: var(--font-primary);
    font-size: 92px;
    line-height: 100%;
    font-weight: 400;
    max-width: 850px;
    width: 90%;
    margin-bottom: 10px;
  }


  .top-header-container > h5 {
    margin-top: -80px;
    font-weight: 300;
    color: rgb(70, 70, 70);
    padding: 5px 20px;
    border-radius: 100px;
    border: 1px solid rgb(223, 223, 223);
    margin-bottom: -40px;
  }

  
  .top-header-subtitle {
    font-family: var(--font-secondary);
    font-size: 24px;
    margin: 10px 0;
    font-weight: 300;
    max-width: 600px;
    width: 90%;
  }
  
  .button-container-1 {
    display: inline-block;
    position: relative;
    background-color: black;
    border-radius: 100px;
    padding: 20px;
    margin-top: 2px;
    overflow: hidden;
    font-family: var(--font-secondary);
    font-weight: 500;
    z-index: 1;
  }

  .button-container-1:hover {
    background-color: var(--blue);
  }

  .header-button {
    transition: ease-in-out 120ms ;
  }
  .header-button:hover {
    transform: scale(1.05);
  }
  
  .header-button:hover  .button-wrapper {
    background: linear-gradient(90deg, #ffffff, white, var(--blue), white);
    background-size: 300% 300%;
    animation: gradientBorder2 3s linear infinite;
    box-shadow: rgba(51, 133, 187, 1) 0px 42px 170px 4px;
  }

  .button-wrapper {
    display: inline-block;
    position: relative;
    box-shadow: rgba(51, 133, 187, 0.36) 0px 42px 170px 4px;
    padding: 0px 2px;
    height: 98px;
    border-radius: 100px;
    overflow: hidden;
    transition: 200ms ease-in-out;
    margin-top: 20px;
    background: linear-gradient(90deg, #ffffff, white, rgba(0, 0, 0, 0.418), white);
    background-size: 300% 300%;
    animation: gradientBorder2 3s linear infinite;
  }
  
  @keyframes gradientBorder2 {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .header-button:hover   .top-header-button  {
    color: #fff; 

  }
  
  .top-header-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    font-size: 16px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.863); 
    font-family: var(--font-secondary);
    position: relative;
    font-size: 18px;
    z-index: 2;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 100px;
  }
  
  .button-container-1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 500%;
    background: url('https://www.transparenttextures.com/patterns/stardust.png') repeat;
    background-size: 100% 200%;
    animation: moveStars 70s linear infinite;
    opacity: 1; /* Sterne besser sichtbar machen */
    z-index: 1;
  }
  
  .top-header-button .button-icon {
    margin-right: 10px;
  }
  
  .top-header-button .light-streak-1 {
    content: '';
    position: absolute;
    filter: blur(5px); /* Light streak blurren */
    bottom: -35px;
    right: -10px;
    width: 300px;
    height: 15px;
    background: radial-gradient(circle, rgb(255, 255, 255), rgba(255, 255, 255, 0.507));
    z-index: 3;
  }

  .top-header-button .light-streak-2 {
    content: '';
    position: absolute;
    filter: blur(5px); /* Light streak blurren */
    top: -35px;
    right: -10px;
    width: 300px;
    height: 15px;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.555), rgba(255, 255, 255, 0.507));
    z-index: 3;
  }

  @keyframes moveStars {
    from { background-position: 0 0; }
    to { background-position: 1000px 1000px; }
  }

    
  
  /* Animation Keyframes */
  @keyframes wobble1 {
    0%, 100% {
      transform: rotate(-2deg);
    }
    50% {
      transform: rotate(2deg);
    }
  }
  
  @keyframes wobble2 {
    0%, 100% {
      transform: rotate(-1.5deg);
    }
    50% {
      transform: rotate(1.5deg);
    }
  }
  
  @keyframes wobble3 {
    0%, 100% {
      transform: rotate(-1deg);
    }
    50% {
      transform: rotate(1deg);
    }
  }
  
  /* Relative images */
  .relative-image {
    width: 180px;
    position: absolute;
  }
  
  .image-1 {
    top: 300px;
    left: calc(50% - 690px);
    animation: wobble1 4s ease-in-out infinite;
    width: 330px;
  }
  
  
  .image-2 {
    top: 90px;
    left: calc(50% + 350px);
    animation: wobble2 3.5s ease-in-out infinite;
    width: 270px;
  }
  
  .image-3 {
    top: 470px;
    left: calc(50% + 300px);
    transform: translateX(-50%);
    animation: wobble3 5s ease-in-out infinite;
    width: 160px;

  }
  


  @media (max-width: 800px) {
    .top-header-title {
      font-size: 72px;
      margin-top: 40px;
      z-index: 1000;
    }
    .top-header-container > h5 {
      margin-top: 0px;
      margin-bottom: -20px;
      z-index: 1000;
      padding: 5px 10px;
      background-color: white;
      font-size: 12px;
      display: none;
    }


  }



  @media (max-width: 800px) {
   
    .relative-image {
      width: 120px;
      position: absolute;
    }
    .image-1 {
      top:30px;
      left: calc(50% - 55%);
      animation: wobble1 4s ease-in-out infinite;
      width: 150px;
    }
    
    
    .image-2 {
      top: 0px;
      left: calc(60% + 20px);
      animation: wobble2 3.5s ease-in-out infinite;
      width: 160px;
    }
    
    .image-3 {
      top: 700px;
      left: calc(50% + 0px);
      transform: translateX(-50%);
      animation: wobble3 5s ease-in-out infinite;
      width: 100px;
      display: none;
  
    }

    .top-header-button {
      z-index: 100;
    }
    
    
  }
  